import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Modal, message } from "antd";
import moment from "moment";

import { IUseBeneficiaries } from "./IUseBeneficiaries";
import { Beneficiaries } from "../Models/Beneficiaries";
import { emptyBeneficiary, emptyBeneficiaryErrors } from "../Assets/Data/EmptyValues";
import { Utils } from "../Utils";

export const useBeneficiaries = ({
	translations,
	investorAPI,
	beneficiaryAPI,
	contractAPI,
	unlockNextStep
}: IUseBeneficiaries) => {
	const [searchParams, _] = useSearchParams();

	const [loading, setLoading] = useState<boolean>(false);
	const [contractId, setContractId] = useState<number>(0);
	const [beneficiaries, setBeneficiaries] = useState<Beneficiaries[]>([{...emptyBeneficiary}]);
	const [nationalitiesList, setNationalitiesList] = useState<any[]>([])
	const [noBeneficiariesError, setNoBeneficiariesError] = useState<boolean>(false);
	const [errors, setErrors] = useState<any[]>([{ ...emptyBeneficiaryErrors }]);
	const [contractLanguage, setContractLanguage] = useState<string>('es');

	useEffect(() => {
		if (searchParams.has('ContractId')) {
			const contractId = Number(searchParams.get('ContractId'));
			contractAPI.getContract(contractId).then(res => {
				if(res.success){
					if(res.data?.idLanguage){
						setContractLanguage(res.data.idLanguage === 1 ? 'es' : 'en');
					}
				}
			});
			configureInitialData(contractId);
			setContractId(contractId);
			getNationalities();
		}
	}, [translations]);

	const getNationalities = () => {
		investorAPI.getCountries().then(res => {
			if (res !== "") {
				setNationalitiesList(res);
			}
			setLoading(false);
		})
		.catch(err => {
			console.log(err);
		});
	};

	const configureInitialData = async(ContractId: number) => {
		setLoading(true);
		await beneficiaryAPI.getBeneficiaries(ContractId).then((res: any) => {
			if(res.success){
				if(res.data.length > 0){
					setBeneficiaries(res.data);
					setErrors(res.data.map(() => ({...emptyBeneficiaryErrors})));
				}else{
					setBeneficiaries([{...emptyBeneficiary}]);
					setErrors([{...emptyBeneficiaryErrors}]);
				}
			}
			setLoading(false);
		}).catch((err: any) => {
			console.log(err);
		})
		setLoading(false);
	};

	const getInfoTitulo = () => {
		Modal.info({
			width: 700,
			content: (
				<div className="titles">
				• <b>{translations.textBeneficiarioUno}</b>{translations.textBeneficiarioDos}
				<br/>
				• <b>{translations.textBeneficiarioTres}</b> {translations.texBenefificiarioCuatro}
				</div>
			),
			onOk: () => { },
		});
	};

	const getInfo = () => {
		Modal.info({
			width: 700,
			content: (
				<div className="titles">
					{translations.tooltipPrpBeneficiario}
				</div>
			),
			onOk: () => { },
		});
	};

	const addRow = () => {
		setNoBeneficiariesError(false)

		let newElement = { ...emptyBeneficiary };
		setBeneficiaries(oldArray => [...oldArray, newElement]);
		setErrors(prevState => [...prevState, {...emptyBeneficiaryErrors}]);
	};

	const validateData = (data: Beneficiaries, index: number) => {
		let isOK = true;
		const newErrors = [...errors];
		if (!data.name) {
			newErrors[index].name = true;
			isOK = false;
		} else if (!Utils.validate('name', data.name)) {
			newErrors[index].invalidNameFormat = true;
			isOK = false;
		}
		if (!data.lastName) {
			newErrors[index].lastName = true;
			isOK = false;
		} else if (!Utils.validate('name', data.lastName)) {
			newErrors[index].invalidLastNameFormat = true;
			isOK = false;
		}
		if (!data.birthDate) {
			newErrors[index].birthDate = true;
			isOK = false;
		}
		if (!data.address) {
			newErrors[index].address = true;
			isOK = false;
		}
		if (!data.nationality) {
			newErrors[index].nationality = true;
			isOK = false;
		}
		if (!data.countryTaxResidence) {
			newErrors[index].countryTaxResidence = true;
			isOK = false;
		}
		if (!data.taxResidence) {
			newErrors[index].taxResidence = true;
			isOK = false;
		}
		if (!data.nif) {
			newErrors[index].nif = true;
			isOK = false;
		} else if (!Utils.validate('document', data.nif!)) {
			newErrors[index].notValidDocument = true;
			isOK = false;
		}
		if (!data.documentExpiration) {
			newErrors[index].documentExpiration = true;
			isOK = false;
		} else if (moment(data.documentExpiration).isBefore(moment())) {
			newErrors[index].notValidExpirationDate = true;
			isOK = false;
		}
		if (!data.participationPercentage) {
			newErrors[index].participationPercentage = true;
			isOK = false;
		}
		if (!data.prp) {
			newErrors[index].prp = true;
			isOK = false;
		}
		if (!data.email) {
			newErrors[index].email = true;
			isOK = false;
		} else if (!Utils.validate('email', data.email)) {
			newErrors[index].notValidEmail = true;
			isOK = false;
		}
		if (!data.role) {
			newErrors[index].role = true;
			isOK = false;
		}
		if (!data.beneficiaryFrom) {
			newErrors[index].beneficiaryFrom = true;
			isOK = false;
		}
		if (!data.beneficiaryTo) {
			newErrors[index].beneficiaryTo = true;
			isOK = false;
		}
		setErrors(newErrors);
		return isOK;
	};

	const postBeneficiaries = async (newBeneficiaries: Beneficiaries[]) => {
		await beneficiaryAPI.saveBeneficiaries(newBeneficiaries)
		.then((res: any) => {
			if (res.success) {
				unlockNextStep();
				message.success(translations.datosGuardados);
			} else {
				message.error(translations.messageError);
			}
		});
	}

	const updateBeneficiaries = async (updatedBeneficiaries: Beneficiaries[]) => {
		await beneficiaryAPI.updateBeneficiaries(updatedBeneficiaries)
		.then((res: any) => {
			if (res.success) {
				message.success(translations.datosGuardados);
			} else {
				message.error(translations.messageError);
			}
		});
	}

	const saveData = async() => {
		if (beneficiaries.length > 0) {
			setLoading(true);
			setNoBeneficiariesError(false)
			let isDataOK = true;
			beneficiaries.map((representative: Beneficiaries, i) => {
				const isDataValid = validateData(representative, i);
				if (isDataOK && !isDataValid) {
					isDataOK = false;
				} else {
					representative.birthDate = moment(representative.birthDate).format('YYYY-MM-DD');
					representative.documentExpiration = moment(representative.documentExpiration).format('YYYY-MM-DD');
					representative.idContract = contractId;
				}
				return representative;
			});
			let newBeneficiaries = beneficiaries.filter((x: any) => !x.id);
			let updatedBeneficiaries = beneficiaries.filter((x: any) => x.id > 0);

			if (isDataOK) {
				if (newBeneficiaries.length > 0) {
					await postBeneficiaries(newBeneficiaries);
				}
				if(updatedBeneficiaries.length > 0){
					await updateBeneficiaries(updatedBeneficiaries);
				}
			} 
		} else {
			setNoBeneficiariesError(true);
		}
		setLoading(false);
	};

	const deleteRow = async (i: number) => {
		if(beneficiaries.length > 1){
			if(beneficiaries[i].id > 0){
				await beneficiaryAPI.deleteBeneficiary(beneficiaries[i].id)
					.then((res: any) => {
						if(res.success){
							setBeneficiaries((prevState: any) => [...prevState.filter((_: any, index: number) => index !== i)]);
							setErrors(prevState => [...prevState.filter((_, index) => index !== i)]);
						}else{
							message.error(translations.messageError);
						}
					});
			}else{
				setBeneficiaries((prevState: any) => [...prevState.filter((_: any, index: number) => index !== i)]);
				setErrors(prevState => [...prevState.filter((_, index) => index !== i)]);
			}
		}else{
			message.error(translations.beneficiarioMinimo);
		}
	};

	const handleChangeData = (index: number) => (e: any) => {
		const { name, value } = e.target;

		setErrors((prevState: any[]) => {
			const updatedErrors = [...prevState];
			updatedErrors[index || 0] = {
				...updatedErrors[index || 0],
				[name]: false
			};
			return updatedErrors;
		});
		setBeneficiaries((prevState: Beneficiaries[]) => {
			const updatedInvestors = [...prevState];
			updatedInvestors[index] = {
				...updatedInvestors[index],
				[name]: value
			};
			return updatedInvestors;
		});
	};
	
	const handleSelectData = (field: string, index?: number) => (value: string) => {
		setErrors((prevState: any[]) => {
			const updatedErrors = [...prevState];
			updatedErrors[index || 0] = {
				...updatedErrors[index || 0],
				[field]: false
			};
			return updatedErrors;
		});
		setBeneficiaries((prevState: Beneficiaries[]) => {
			const updatedInvestors = [...prevState];
			updatedInvestors[index || 0] = {
				...updatedInvestors[index || 0],
				[field]: value
			};
			return updatedInvestors;
		});
	};

	const handleChangeDate = (field: string, index: number) => (date: any) => {
		setBeneficiaries((prevState: Beneficiaries[]) => {
			const updatedInvestors = [...prevState];
			updatedInvestors[index] = {
				...updatedInvestors[index],
				[field]: date ? date.format('YYYY-MM-DD') : undefined
			};
			return updatedInvestors;
		});
		setErrors((prevState: any[]) => {
			const updatedErrors = [...prevState];
			updatedErrors[index || 0] = {
				...updatedErrors[index || 0],
				[field]: false
			};
			return updatedErrors;
		});
	};

	return {
		loading,
		beneficiaries,
		errors,
		nationalitiesList,
		contractLanguage,
		addRow,
		getInfoTitulo,
		handleChangeData,
		handleSelectData,
		handleChangeDate,
		getInfo,
		deleteRow,
		saveData,
		noBeneficiariesError
	};
};