import { Col, Input } from 'antd';

import { IEmailField } from './IEmailField';

const EmailField = ({
	value,
	fieldName,
	translations,
	errors,
	index = 0,
	handleChangeData
}: IEmailField) => (
	<Col xs={12} md={6} lg={6}>
		<label className="required">
			{translations.email}
		</label>
		<Input
			className="input-field"
			name={fieldName}
			value={value}
			onChange={handleChangeData(index)}
			placeholder={translations.email}
			status={errors[index][fieldName] || errors[index].invalidEmailFormat ? "error": ""}
		/>
		<span className={`error ${errors[index][fieldName] ? 'show' : null}`}>
			{translations.completeCampo}
		</span>
		<span className={`error ${errors[index].invalidEmailFormat ? "show" : null}`}>
			{translations.completeEmailValido}
		</span>
	</Col>
);

export default EmailField;