import { Beneficiaries } from "../../Models/Beneficiaries";
import { CrsDTO } from "../../Models/CrsAPI";
import { FatcaDTO } from "../../Models/FatcaAPI";
import { InvestorDTO } from "../../Models/InvestorDTO";
import { InvestorErrorsDTO } from "../../Models/InvestorErrorsDTO";
import { LegalInvestorStatus, PhysicalInvestorStatus } from "../../Models/InvestorStatus";
import { Representative } from "../../Models/Representative";
import { TaxHolder } from "../../Models/TaxHolders";

export const emptyInvestor: InvestorDTO = {
	id: 0,
	name: "",
	lastName: "",
	birthDate: "",
	birthPlace: "",
	nationality: undefined,
	mainResidence: "",
	mainResidenceCountry: undefined,
	postalAddress: "",
	emailContact: "",
	phoneNumber: "",
	phonePrefix: "",
	document: "",
	dni: "",
	nif: "",
	cif: "",
	passport: "",
	residenceCard: "",
	managerName: "",
	constitutionDate: "",
	registeredOffice: "",
	countryTaxResidence: undefined,
	taxIdentification: "",
	ocupation: "",
	employerName: "",
	prp: "",
	familyPRP: "",
	bankDeposits: "",
	bankAddress: "",
	biC_SWIFT: "",
	iban: "",
	american: false,
	notNIFReason: 0,
	notNIFDetailReason: "",
	legalForm: "",
	incorporationDate: "",
	constitutionPlace: "",
	jurisdiction: "",
	webPage: "",
	contactPerson: "",
	commercialRegister: "",
	activityName: "",
	operationCountries: "",
	organism: false,
	regulationOrganism: "",
	webRegulator: "",
	regulatoryRecord: "",
	contributor: false,
	marketName: "",
	marketCountry: "",
	countryRegulator: "",
	marketWeb: "",
	marketRegistry: "",
	fatca: "",
	textoFatca: "",
	idContract: 0,
	sector: "",
	source: "",
	incomes: "",
	situation: ""
};

export const emptyInvestorErrors: InvestorErrorsDTO = {
	name: false,
	lastName: false,
	birthDate: false,
	birthPlace: false,
	nationality: false,
	mainResidence: false,
	mainResidenceCountry: false,
	emailContact: false,
	phone: false,
	documentType: false,
	document: false,
	taxResidence: false,
	countryTaxResidence: false,
	ocupation: false,
	familyPRP: false,
	prp: false,
	bankDeposits: false,
	bankAddress: false,
	iban: false,
	biC_SWIFT: false,
	situation: false,
	incomes: false,
	source: false,
	sector: false,
	lei: false,
	documentExpiration: false,
	documentFormat: false,
	notValidExpirationDate: false,
	phonePrefix: false,
	phoneNumber: false,
	invalidNameFormat: false,
	invalidLastNameFormat: false,
	invalidEmailFormat: false
}

export const emptyInvestorStatus: PhysicalInvestorStatus = {
	id: 0,
	idContract: 0,
	status1_1: false,
	status1_2: false,
	status1_3: false,
	status2: 0,
	status3: "",
	status4: false,
	status5: false
}

export const emptyLegalInvestorStatus: LegalInvestorStatus = {
	id: 0,
	idContract: 0,
	status1: 0,
	status1_12_1: false,
	status1_12_2: false,
	status1_12_3: false,
	status2: false,
	status3_1: false,
	status3_2: false,
	status3_3: false,
	status4: 0,
	status5: "",
	status6: false,
	status7: false
}


export const emptyRepresentative: Representative = {
	id: 0,
	idContract: 0,
	name: "",
	lastName: "",
	documentType: "",
	documentNumber: "",
	documentExpiration: "",
	birthDate: "",
	nationality: "",
	administrator: "",
	administratorType: "",
	isSignatory: false,
	email: "",
	prP5: false,
	namePRP51: "",
	positionPRP51: "",
	namePRP52: "",
	positionPRP52: "",
	administratorFrom: "",
	administratorTo: "",
	countryTaxResidence: "",
}

export const emptyRepresentativeErrors = {
	name: false,
	lastName: false,
	documentNumber: false,
	documentExpiration: false,
	birthDate: false,
	nationality: false,
	administrator: false,
	administratorType: false,
	namePRP51: false,
	positionPRP51: false,
	email: false,
	documentFormat: false,
	documentExpirationInvalid: false,
	administratorFrom: false,
	administratorTo: false,
	countryTaxResidence: false,
}

export const emptyFatca: FatcaDTO= {
	id: 0,
	idContract: 0,
}

export const emptyCrs: CrsDTO ={
	id: 0,
	idContract: 0,
}

export const emptyBeneficiary: Beneficiaries = {
	id: 0,
	idContract: 0,
	name: "",
	lastName: "",
	birthDate: "",
	address: "",
	nationality: "",
	countryTaxResidence: "",
	taxResidence: "",
	fundsSource: "",
	email: "",
	role: "",
	nif: "",
	documentExpiration: "",
	participationPercentage: 0,
	prp: "",
	beneficiaryFrom: "",
	beneficiaryTo: "",
}

export const emptyBeneficiaryErrors = {
	name: false,
	lastName: false,
	birthDate: false,
	address: false,
	nationality: false,
	countryTaxResidence: false,
	taxResidence: false,
	fundsSource: false,
	email: false,
	role: false,
	nif: false,
	documentExpiration: false,
	participationPercentage: false,
	prp: false,
	beneficiaryFrom: false,
	beneficiaryTo: false,
	notValidExpirationDate: false,
	notValidDocument: false,
	notValidEmail: false,
	invalidNameFormat: false,
	invalidLastNameFormat: false,
}

export const emptyTaxHolder: TaxHolder = {
	id: 0,
	idContract: 0,
	name: "",
	lastName: "",
	taxResidency: "",
	participationPercentage: ""
}

export const emptyTaxHolderErrors = {
	name: false,
	lastName: false,
	taxResidency: false,
	participationPercentage: false
}