import {
	Button,
	Col,
	Input,
	InputNumber,
	Row,
	Select,
	Tooltip,
	Result,
	Popconfirm,
} from "antd";
import {
	InfoCircleOutlined,
	MinusCircleOutlined,
	PlusCircleOutlined,
} from "@ant-design/icons";

import { InvestorAPI } from "../../../Api/InvestorAPI";
import { TemplateAPI } from "../../../Api/TemplatesAPI";
import { ContractAPI } from "../../../Api/ContractAPI";

import { useInvestorData } from "../../../Hooks/useInvestorData";

import FormLayout from "../../FormLayout/FormLayout";
import NameField from "../../Fields/NameField/NameField";
import LastNameField from "../../Fields/LastNameField/LastNameField";
import DocumentTypeField from "../../Fields/DocumentTypeField/DocumentTypeField";
import DocumentExpirationField from "../../Fields/DocumentExpirationField/DocumentExpirationField";
import EmailField from "../../Fields/EmailField/EmailField";
import DateField from "../../Fields/DateField/DateField";
import CountryField from "../../Fields/CountryField/CountryField";
import TextField from "../../Fields/TextField/TextField";
import FundsSourceField from "../../Fields/FundsSourceField/FundsSourceField";

import { IInvestorData } from "./IInvestorData";

import "./InvestorData.scss";

const { Option } = Select;

const InvestorData = ({
	baseURL,
	baseURLExternal,
	selectedPersonType,
	translations,
	institutionData,
	getAuthToken,
	setSelectedPersonType,
	unlockNextStep,
	userIdInstitution,
}: IInvestorData) => {
	const contractAPI = ContractAPI(baseURL, baseURLExternal, getAuthToken);
	const investorsAPI = InvestorAPI(baseURL, baseURLExternal, getAuthToken);
	const templatesAPI = TemplateAPI(baseURL, getAuthToken);

  	let isCreand = userIdInstitution === 1231;

	const {
		loading,
		noContent,
		contractErrors,
		investorsErrors,
		contractData,
		contractLanguage,
		error,
		onChangeVehicle,
		onChangePersonType,
		isAllowedAmount,
		onChangeCommitment,
		saveData,
		onChangeLanguage,
		handleChangeData,
		handleSelectData,
		handleChangeDate,
		investorsData,
		addInvestor,
		onDeleteInvestor,
		onChangeCreandData,
		nationalitiesList
	} = useInvestorData({
		translations,
		contractAPI,
		investorsAPI,
		institutionData,
		templatesAPI,
		selectedPersonType,
		setSelectedPersonType,
		unlockNextStep,
		userIdInstitution,
		isCreand
	});

  	return (
    	<>
			{!noContent ? (
				<FormLayout
					translations={translations}
					loading={loading}
					title={translations.datosFirmantes}
					subTitle={translations.completarDatos}
					onClick={saveData}
					nextStep={unlockNextStep}
					enableNextStep={(contractData.id && investorsData.length > 0) ? true : false}
					confirm={contractData.id ? null : translations.confirmContract }
					disabledSaveButton={
						(contractData.idContractState !== 1 &&
						contractData.idContractState !== 2) ||
						error || contractErrors.noTemplate
					}
				>
					<Row gutter={[16, 16]} className="row-wtdh">
						<Col xs={24} md={12} lg={12}>
							<label className="required">
								{translations.vehiculoContratar}
							</label>
							<Select
								className="input-field"
								value={contractData.vehicleId || undefined}
								placeholder={translations.seleccionVehiculo}
								onChange={onChangeVehicle}
								disabled={contractData.id !== 0}
								status={contractErrors.noVehicle ? "error" : ""}
							>
								{institutionData?.institutionValues.map((x) => (
									<Option value={x.vehicleId}>{x.vehicleLegalName}</Option>
								))}
							</Select>
							<span className={`error ${contractErrors.noVehicle ? "show" : null}`}>
								{translations.seleccionVehiculo}
							</span>
						</Col>
						{/* CREAND DATA BP AND CONTAINER */}
						{isCreand && (
							<>
								<Col xs={24} md={6} lg={6}>
									<label className="required"> BP</label>
									<InputNumber
										className="input-field"
										placeholder="BP"
										name="bp"
										value={contractData.bp}
										disabled={contractData.id !== 0}
										status={contractErrors.bp ? "error" : ""}
										onChange={(e: any) => onChangeCreandData(e, "bp")}
									/>
									<span className={`error ${contractErrors.bp ? "show" : null}`}>
										{translations.noBP}
									</span>
								</Col>
								<Col xs={24} md={6} lg={6}>
									<label className="required"> Container</label>
									<InputNumber
										className="input-field"
										placeholder="Container"
										name="container"
										disabled={contractData.id !== 0}
										status={contractErrors.container ? "error" : ""}
										value={contractData.container}
										onChange={(e: any) => onChangeCreandData(e, "container")}
									/>
									<span className={`error ${contractErrors.container ? "show" : null}`}>
										{translations.noContainer}
									</span>
								</Col>
							</>
						)}
					</Row>
					<Row gutter={[16, 0]} className="row-wtdh">
						<Col xs={12} md={6} lg={6}>
							<label className="required"> {translations.tipoPersona}</label>
							<Select
								className="input-field"
								value={contractData.idPersonType}
								onChange={onChangePersonType}
								disabled={contractData.id !== 0}
							>
								<Option value={2}>{translations.juridica}</Option>
								<Option value={1}>{translations.fisica}</Option>
							</Select>
						</Col>
						<Col xs={12} md={6} lg={6}>
							<label className="required"> {translations.compromiso}</label>
							<InputNumber
								className="input-field"
								name="commitment"
								value={contractData.commitment}
								onBlur={(e: any) => isAllowedAmount(e.target.value)}
								onChange={onChangeCommitment}
								status={contractErrors.noCommitment ? "error" : ""}
							/>
							<span className={`error ${contractErrors.noCommitment ? "show" : null}`}>
								{translations.messageCompromiso}
							</span>
						</Col>
						<Col xs={12} md={6} lg={6}>
							<label className="required"> {translations.language}</label>
							<Select
								className="input-field"
								value={contractData.idLanguage}
								onChange={onChangeLanguage}
								disabled={contractData.id !== 0}
							>
								<Option value={1}>{translations.spanish}</Option>
								<Option value={2}>{translations.english}</Option>
								<Option value={3}>{translations.portuguese}</Option> 
							</Select>
						</Col>
						<Col xs={12} md={6} lg={6}>
							<p>
								<label className="required">
									{translations.personasFirmantes}
								</label>
								<Tooltip
									placement="topLeft"
									title={translations.tootltipPersonasFirmantes}
								>
									<InfoCircleOutlined className="tooltip" />
								</Tooltip>
							</p>
							<div className="numSignaturies">
								<span>{contractData.numSignatories}</span>
								<Button type="text" className="save-btn" onClick={addInvestor} disabled={contractData.id !== 0}>
									<PlusCircleOutlined />
								</Button>
							</div>
						</Col>
					</Row>
					{contractErrors.noTemplate &&
						<span className={`error-big ${contractErrors.noTemplate ? "show" : null}`}>
							{translations.errorTemplate}
						</span>
					}
					{/* Investors */}
					<Row className="investor-list">
						{contractData.numSignatories > 0 &&
							investorsData.map((investor: any, index: number) => (
								<div className="subitem-line">
									<h3>
										{translations.datosFirmante} {index + 1}
									</h3>
									<Row gutter={[16, 8]} justify="start">
										<NameField
											name={ investor.name }
											translations={ translations}
											errors={ investorsErrors }
											index={ index }
											handleChangeData={ handleChangeData }
										/>
										<LastNameField
											lastName={ investor.lastName }
											translations={ translations }
											errors={ investorsErrors }
											index={ index }
											handleChangeData={ handleChangeData }
										/>
										<DocumentTypeField
											value={ investor.documentType! }
											index={ index }
											translations={ translations }
											errors={ investorsErrors }
											handleSelect={ handleSelectData }
										/>
										<Col xs={12} md={6} lg={6}>
											<label className="required">
												{translations.documentNumber}
												<Tooltip placement="top" title={<span>{translations.documentNumberTooltip}</span>}>
													{" "} <InfoCircleOutlined />
												</Tooltip>
											</label>
											<Input
												className="input-field"
												name="document"
												value={investor.document}
												onChange={handleChangeData(index)}
												placeholder={translations.documentNumber}
												status={investorsErrors[index].document ? "error" : "" }
											/>
											<span className={`error ${investorsErrors[index].document ? 'show' : null}`}>{translations.completeCampo}</span>
											<span className={`error ${investorsErrors[index].documentFormat ? 'show' : null}`}>{translations.invalidIdDocument}</span>
										</Col>
										<DocumentExpirationField
											documentExpiration={investor.docValidThru}
											translations={translations}
											errors={investorsErrors}
											fieldName="docValidThru"
											index={index}
											handleDatePicker={handleChangeDate}	
										/>
										<EmailField
											value={investor.emailContact}
											fieldName="emailContact"
											translations={translations}
											errors={investorsErrors}
											index={index}
											handleChangeData={handleChangeData}
										/>
										<DateField
											value={investor.birthDate}
											fieldName="birthDate"
											translations={translations}
											errors={investorsErrors}
											index={index}
											handleDatePicker={handleChangeDate}
										/>
										<CountryField
											value={investor.nationality}
											fieldName="nationality"
											nationalitiesList={nationalitiesList}
											translations={translations}
											errors={investorsErrors}
											index={index}
											handleSelect={handleSelectData}
										/>
										<TextField
											size='m'
											value={investor.mainResidence}
											fieldName="mainResidence"
											placeholder={translations.mainResidence}
											translations={translations}
											required
											errors={investorsErrors}
											index={index}
											handleChange={handleChangeData}
										/>
										<CountryField
											value={investor.mainResidenceCountry}
											fieldName="mainResidenceCountry"
											nationalitiesList={nationalitiesList}
											translations={translations}
											errors={investorsErrors}
											index={index}
											handleSelect={handleSelectData}
										/>
										<TextField
											size='s'
											value={investor.phonePrefix}
											fieldName="phonePrefix"
											placeholder="+34"
											translations={translations}
											required
											errors={investorsErrors}
											index={index}
											handleChange={handleChangeData}
										/>
										<TextField
											size='s'
											value={investor.phoneNumber}
											fieldName="phoneNumber"
											placeholder="000 000 000"
											translations={translations}
											required
											errors={investorsErrors}
											index={index}
											handleChange={handleChangeData}
										/>
										<Col xs={12} md={6} lg={6} className="delete-investor">
											{
												index > 0 ? 
													<Popconfirm 
														title= {translations.questionDeleteInvestor}
														okText={translations.deleteInvestor}
														cancelText={translations.no}
														placement="left"
														disabled={contractData.id !== 0}
														onConfirm={() => onDeleteInvestor(index)}
													>
														<Button danger type="text">
															<MinusCircleOutlined /> {translations.deleteInvestor} {index+1}
														</Button>
													</Popconfirm>
													: null
											}
										</Col>
									</Row>
								</div>
							)
						)}
					</Row>
					<Row className="data-protection">
						<strong>{translations.clausulaProteccionTitulo}</strong>
						<span>{translations.clausulaProteccion}</span>
					</Row>
				</FormLayout>
			) : (
				<Result
					status="warning"
					title={translations.messageContrato}
					extra={
						<Button type="primary" key="console">
							{translations.inicio}
						</Button>
					}
				/>
			)}
    	</>
  	);
};

export default InvestorData;
