import { Col, Select } from "antd";

import { IDocumentTypeField } from "./IDocumentTypeField";

const { Option } = Select;

const DocumentTypeField = ({
	value,
	index,
	translations,
	errors,
	handleSelect,
}: IDocumentTypeField) => (
	<Col xs={24} md={6} lg={6} className="titles">
		<label className="required"> {translations.documentType} </label>
		<Select
			className="input-field"
			value={value}
			onChange={handleSelect("documentType", index || 0)}
			status={errors[index].documentType ? 'error' : ''}
			placeholder="Seleccione una opción"
		>
			<Option value="dni">DNI</Option>
			<Option value="nif">NIF</Option>
			<Option value="passport">{translations.passport}</Option>
			<Option value="residenceCard">{translations.residentCard}</Option>
		</Select>
		<span className={`error ${errors[index].documentType ? 'show' : null}`}>{translations.seleccioneOpcion}</span>
	</Col>
);

export default DocumentTypeField;