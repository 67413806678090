import { Col, DatePicker } from "antd";
import moment from "moment";

import { IDateField } from "./IDateField";

const DateField = ({
	value,
	fieldName,
	translations,
	errors,
	index,
	handleDatePicker
}: IDateField) => (
	<Col xs={24} md={6} lg={6} className="titles">
		<label className="required"> {translations[fieldName]} </label>
		<DatePicker
			allowClear={false}
			className="input-field"
			name={fieldName}
			value={value ? moment(value) : undefined}
			format="DD/MM/YYYY"
			onChange={handleDatePicker(fieldName, !!index ? index : 0)}
			status={errors[index][fieldName] ? 'error' : ''}
		/>
		<span className={`error ${errors[index][fieldName] ? 'show' : null}`}>{translations.completeCampo}</span>
	</Col>
);

export default DateField;