import { Col, Input } from "antd";

import { ITextField } from "./ITextField";

const sizes = {
	s: 6,
	m: 12,
	l: 24
}

const DateField = ({
	size,
	placeholder,
	value,
	fieldName,
	translations,
	required,
	errors,
	index,
	handleChange
}: ITextField) => (
	<Col xs={24} md={sizes[size]} lg={sizes[size]} className="titles">
		<p>
			<label className={required ? "required" : ""}> {translations[fieldName]} </label>
		</p>
		<Input
			placeholder={placeholder}
			type="text"
			name={fieldName}
			value={value}
			onChange={handleChange(index)}
			status={errors && errors[index][fieldName] ? 'error' : ''}
		/>
		{
			required && errors ?
				<span className={`error ${errors[index][fieldName] ? 'show' : null}`}>{translations.completeCampo}</span>
			: null
		}
	</Col>
);

export default DateField;