import { Col, Input, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import { INameField } from "./INameField";

const NameField = ({ name, translations, index = 0, errors, handleChangeData }: INameField) => (
	<Col xs={12} md={6} lg={6}>
		<label className="required">
			{translations.nombreFirmante}
			<Tooltip placement="top" title={<span>{translations.signerNameTooltip}</span>}>
				{" "} <InfoCircleOutlined />
			</Tooltip>
		</label>
		<Input
			className="input-field"
			name="name"
			value={name}
			onChange={handleChangeData(index)}
			placeholder={translations.nombreFirmante}
			status={ errors[index].name || errors[index].invalidNameFormat ? "error" : "" }
		/>
		<span className={`error ${errors[index].name ? 'show' : null}`}>
			{translations.completeCampo}
		</span>
		<span className={`error ${errors[index].invalidNameFormat ? 'show' : null}`}>{translations.invalidFormat}</span>
	</Col>
);

export default NameField;