import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { message } from "antd";
import moment from "moment";

import { IUsePhysicalInvestorForm } from "./IUsePhysicalInvestorForm";

import { Utils } from "../Utils";
import { InvestorDTO } from "../Models/InvestorDTO";
import { InvestorErrorsDTO } from "../Models/InvestorErrorsDTO";
import { emptyInvestorErrors } from "../Assets/Data/EmptyValues";

export const usePhysicalInvestorForm = ({
	translations,
	investorAPI,
	unlockNextStep,
	contractAPI
}: IUsePhysicalInvestorForm) => {
	const [searchParams, _] = useSearchParams();

	const [loading, setLoading] = useState<boolean>(false);
	const [noContent, setNoContent] = useState<boolean>(false);
	const [nationalitiesList, setNationalitiesList] = useState<any[]>([]);
	const [investorsData, setInvestorsData] = useState<InvestorDTO[]>([]);
	const [contractLanguage, setContractLanguage] = useState<string>('es');

	const [errors, setErrors] = useState<InvestorErrorsDTO[]>([]);
	const [hasErrors, setHasErrors] = useState<boolean>(true);

	const [activityInList, setActivityInList] = useState<boolean>(true)
	const [sourceInList, setSourceInList] = useState<boolean>(true)

	useEffect(() => {
		if (searchParams.has('ContractId')) {
			configureInitialData(Number(searchParams.get('ContractId')));
			getNationalities();
		}
	}, [searchParams.get('ContractId')]);

	const configureInitialData = async(ContractId: number) => {
		setLoading(true);
		await investorAPI.getInvestors(ContractId).then((res: any) => {
			if(res.success){
				if(res.data.length > 0 ) {
					res.data.map((investor: InvestorDTO) => {
						if(investor.dni) investor.documentType = 'dni';
						if(investor.nif) investor.documentType = 'nif';
						if(investor.passport) investor.documentType = 'passport';
						if(investor.residenceCard) investor.documentType = 'residenceCard';

						errors.push({ ...emptyInvestorErrors });
						return investor;
					});
					setInvestorsData(res.data);
				}
			} else {
				setNoContent(true);
			}
			setLoading(false);
		}).catch((err: any) => {
			console.log(err);
		})
		setLoading(false);
		contractAPI.getContract(ContractId).then(res => {
			if(res.success){
				if(res.data?.idLanguage){
					setContractLanguage(res.data.idLanguage === 1 ? 'es' : 'en');
				}
			}
		})
	};

	const handleChangeData = (index: number) => (e: any) => {
		const { name, value } = e.target;
		setErrors((prevState: InvestorErrorsDTO[]) => {
			const updatedErrors = [...prevState];
			updatedErrors[index || 0] = {
				...updatedErrors[index || 0],
				[name]: false
			};
			return updatedErrors;
		});
		setInvestorsData((prevState: InvestorDTO[]) => {
			const updatedInvestors = [...prevState];
			updatedInvestors[index] = {
				...updatedInvestors[index],
				[name]: value
			};
			return updatedInvestors;
		});
	};

	const handleChangeDate = (field: string, index: number) => (date: any) => {
		setInvestorsData((prevState: InvestorDTO[]) => {
			const updatedInvestors = [...prevState];
			updatedInvestors[index] = {
				...updatedInvestors[index],
				[field]: date ? date.format('YYYY-MM-DD') : undefined
			};
			return updatedInvestors;
		});
		setErrors((prevState: InvestorErrorsDTO[]) => {
			const updatedErrors = [...prevState];
			updatedErrors[index || 0] = {
				...updatedErrors[index || 0],
				[field]: false
			};
			return updatedErrors;
		});
	};

	const onChangeDate = (field: string, index: number) => (date: any) => {
		setInvestorsData((prevState: InvestorDTO[]) => {
			const updatedInvestors = [...prevState];
			updatedInvestors[index] = {
				...updatedInvestors[index],
				[field]: date ? date.format('YYYY-MM-DD') : undefined
			};
			return updatedInvestors;
		});
		setErrors((prevState: InvestorErrorsDTO[]) => {
			const updatedErrors = [...prevState];
			updatedErrors[index || 0] = {
				...updatedErrors[index || 0],
				[field]: false
			};
			return updatedErrors;
		});
	};

	const handleSelectData = (field: string, index?: number) => (value: string) => {
		field === 'sector' && setActivityInList(value !== 'Otro');
		field === 'source' && setSourceInList(value !== 'Otro');

		setErrors((prevState: InvestorErrorsDTO[]) => {
			const updatedErrors = [...prevState];
			updatedErrors[index || 0] = {
				...updatedErrors[index || 0],
				[field]: false
			};
			return updatedErrors;
		});
		setInvestorsData((prevState: InvestorDTO[]) => {
			const updatedInvestors = [...prevState];
			updatedInvestors[index || 0] = {
				...updatedInvestors[index || 0],
				[field]: value
			};
			return updatedInvestors;
		});
	};

	const handleCheckedData = (index: number) => (e: any) => {
		const { name } = e.target;
		setInvestorsData((prevState: InvestorDTO[]) => {
			const updatedInvestors = [...prevState];
			updatedInvestors[index] = {
				...updatedInvestors[index],
				[name]: e.target.checked
			};
			return updatedInvestors;
		});
	};

	const validateData = (investorsData: InvestorDTO[]) => {
		let isOK = true;
		investorsData.map((investorData: InvestorDTO, index: number) => {
			const investorErrors: InvestorErrorsDTO = { ...emptyInvestorErrors };
			if (!investorData.birthDate) {
				investorErrors.birthDate = true;
				isOK = false;
			}
			if (!investorData.birthPlace) {
				investorErrors.birthPlace = true;
				isOK = false;
			}
			if (!investorData.nationality) {
				investorErrors.nationality = true;
				isOK = false;
			}
			if (!investorData.mainResidence) {
				investorErrors.mainResidence = true;
				isOK = false;
			}
			if (!investorData.mainResidenceCountry) {
				investorErrors.mainResidenceCountry = true;
				isOK = false;
			}
			if (!investorData.emailContact || !Utils.validate("email", investorData.emailContact)) {
				investorErrors.emailContact = true;
				isOK = false;
			}
			if (!investorData.phoneNumber) {
				investorErrors.phoneNumber = true;
				isOK = false;
			}
			if (!investorData.phonePrefix) {
				investorErrors.phonePrefix = true;
				isOK = false;
			}
			if (!investorData.documentType) {
				investorErrors.documentType = true;
				isOK = false
			}
			const document = investorData.dni || investorData.nif || investorData.passport || investorData.residenceCard;
			if (!document) {
				investorErrors.document = true;
				isOK = false;
			} else if (!/^[A-Z0-9]*$/g.test(document!)) {
				investorErrors.documentFormat = true;
				isOK = false;
			}
			if (!investorData.docValidThru) {
				investorErrors.documentExpiration = true;
				isOK = false;
			} else if (moment(investorData.docValidThru).isBefore(moment())) {
				investorErrors.notValidExpirationDate = true;
				isOK = false;
			}
			if (!investorData.taxResidence) {
				investorErrors.taxResidence = true;
				isOK = false;
			}
			if (!investorData.countryTaxResidence) {
				investorErrors.countryTaxResidence = true;
				isOK = false;
			}
			if (!investorData.ocupation) {
				investorErrors.ocupation = true;
				isOK = false;
			}
			if ((investorData.sector === 'Otro' && !investorData.otherSector) || !investorData.sector) {
				investorErrors.sector = true;
				isOK = false;
			}
			if (!investorData.familyPRP) {
				investorErrors.familyPRP = true;
				isOK = false;
			}
			if (!investorData.prp) {
				investorErrors.prp = true;
				isOK = false;
			}
			if (!investorData.bankDeposits) {
				investorErrors.bankDeposits = true;
				isOK = false;
			}
			if (!investorData.bankAddress) {
				investorErrors.bankAddress = true;
				isOK = false;
			}
			if (!investorData.iban) {
				investorErrors.iban = true;
				isOK = false;
			}
			if (!investorData.biC_SWIFT) {
				investorErrors.biC_SWIFT = true;
				isOK = false;
			}
			if (!investorData.situation) {
				investorErrors.situation = true;
				isOK = false;
			}
			if (!investorData.incomes) {
				investorErrors.incomes = true;
				isOK = false;
			}
			if ((investorData.source === 'Otro' && !investorData.otherSource) || !investorData.source) {
				investorErrors.source = true;
				isOK = false;
			}
			setErrors((prevErrors) => {
				const newErrors = [...prevErrors];
				newErrors[index] = investorErrors;
				return newErrors;
			});
			setHasErrors(!isOK);
		});

		return isOK;
	};

	const saveData = () => {
		setLoading(true);
		if (validateData(investorsData)) {
			const copyInvestorsData = [ ...investorsData ];
			copyInvestorsData.map((investorData: InvestorDTO) => {
				investorData.sector = investorData.sector === 'Otro' ? investorData.otherSector : investorData.sector;
				investorData.source = investorData.source === 'Otro' ? investorData.otherSource : investorData.source;
				investorData.docValidThru = moment(investorData.docValidThru).format('YYYY-MM-DD');
				investorData.birthDate = moment(investorData.birthDate).format('YYYY-MM-DD');
			});
			investorAPI.updateInvestors(copyInvestorsData).then((res: any) => {
				if(res.success){
					message.success(translations.datosGuardados);
					unlockNextStep();
				}else{
					message.error(translations.messageError);
				}
				setLoading(false);
			});
		} else {
			setLoading(false);
		}
	};

	const getNationalities = () => {
		investorAPI.getCountries().then((res: any) => {
			if (res !== "") {
				setNationalitiesList(res);
			}
		})
		.catch((err: any) => {
			console.log(err);
		});
	};

	return {
		loading,
		noContent,
		errors,
		hasErrors,
		investorsData,
		nationalitiesList,
		activityInList,
		sourceInList,
		onChangeDate,
		handleChangeData,
		handleSelectData,
		handleChangeDate,
		handleCheckedData,
		saveData,
		contractLanguage
	};
};