import { Button, Checkbox, Col, Divider, Input, Radio, Result, Row, Select, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { InvestorAPI } from '../../../Api/InvestorAPI';
import { ContractAPI } from '../../../Api/ContractAPI';

import { usePhysicalInvestorForm } from '../../../Hooks/usePhysicalInvestorForm';

import FormLayout from '../../FormLayout/FormLayout';
import CountryField from '../../Fields/CountryField/CountryField';
import DateField from '../../Fields/DateField/DateField';
import TextField from '../../Fields/TextField/TextField';
import DocumentExpirationField from '../../Fields/DocumentExpirationField/DocumentExpirationField';
import TaxIdentificationField from '../../Fields/TaxIdentificationField/TaxIdentificationField';
import DocumentTypeField from '../../Fields/DocumentTypeField/DocumentTypeField';

import { IPhysicalInvestorForm } from './IPhysicalInvestorForm';
import { InvestorDTO } from '../../../Models/InvestorDTO';

import { activityOptionsLanguages, incomeOptionsLanguages, sectorOptionsLanguages, sourceOptionsLanguages } from './ActivityLists';

import './PhysicalInvestorForm.scss';

const { Option } = Select;

const PhysicalInvestorForm = ({
	baseURL,
	baseURLExternal,
	translations,
	getAuthToken,
	unlockNextStep
}: IPhysicalInvestorForm) => {
	const investorAPI = InvestorAPI(baseURL, baseURLExternal, getAuthToken);
	const contractAPI = ContractAPI(baseURL, baseURLExternal, getAuthToken);
	const {
		loading,
		noContent,
		errors,
		hasErrors,
		investorsData,
		nationalitiesList,
		activityInList,
		sourceInList,
		onChangeDate,
		handleChangeData,
		handleSelectData,
		handleChangeDate,
		handleCheckedData,
		saveData,
		contractLanguage
	} = usePhysicalInvestorForm({
		translations,
		investorAPI,
		contractAPI,
		unlockNextStep
	});

	return (
		<FormLayout 
			translations={translations}
			loading={loading}
			title={translations.formularioInversor}
			subTitle={translations.encabezadoFormulario}
			onClick={saveData}
			disabledSaveButton={noContent}
			nextStep={unlockNextStep}
			enableNextStep={!hasErrors}
		>	
			<>
				{!noContent ?
					(
						investorsData.map((investorData: InvestorDTO, index: number) => (
							<>
								<h3>
									{translations.datosFirmante} {index + 1}
								</h3>
								<Row gutter={[16, 16]}>
									<DateField
										value={investorData.birthDate}
										fieldName="birthDate"
										translations={translations}
										errors={errors}
										index={index}
										handleDatePicker={onChangeDate}
									/>
									<TextField
										size='m'
										placeholder={translations.placeholderLugarNac}
										value={investorData.birthPlace}
										fieldName="birthPlace"
										translations={translations}
										required
										errors={errors}
										index={index}
										handleChange={handleChangeData}
									/>
									<CountryField
										value={investorData.nationality}
										fieldName="nationality"
										nationalitiesList={nationalitiesList}
										translations={translations}
										errors={errors}
										index={index}
										handleSelect={handleSelectData}
									/>
									<TextField
										size='m'
										value={investorData.mainResidence}
										fieldName="mainResidence"
										translations={translations}
										required
										errors={errors}
										index={index}
										handleChange={handleChangeData}
									/>
									<CountryField
										value={investorData.mainResidenceCountry}
										fieldName="mainResidenceCountry"
										nationalitiesList={nationalitiesList}
										translations={translations}
										errors={errors}
										index={index}
										handleSelect={handleSelectData}
									/>
									<TextField
										size='s'
										value={investorData.phonePrefix}
										fieldName="phonePrefix"
										translations={translations}
										required
										errors={errors}
										index={index}
										handleChange={handleChangeData}
									/>
									<TextField
										size='s'
										value={investorData.phoneNumber}
										fieldName="phoneNumber"
										translations={translations}
										required
										errors={errors}
										index={index}
										handleChange={handleChangeData}
									/>
									<TextField
										size='m'
										value={investorData.emailContact}
										fieldName="emailContact"
										translations={translations}
										required
										errors={errors}
										index={index}
										handleChange={handleChangeData}
									/>
									<TextField
										size='s'
										value={investorData.postalAddress}
										fieldName="postalAddress"
										translations={translations}
										required={false}
										index={index}
										handleChange={handleChangeData}
									/>
									<DocumentTypeField
										value={investorData.documentType!}
										index={index}
										translations={translations}
										errors={errors}
										handleSelect={handleSelectData}
									/>
									<Col xs={12} md={6} lg={6}>
										<label className="required">
											{translations.documentNumber}
											<Tooltip placement="top" title={<span>{translations.documentNumberTooltip}</span>}>
												{" "} <InfoCircleOutlined />
											</Tooltip>
										</label>
										<Input
											className="input-field"
											value={investorData.dni || investorData.nif || investorData.passport || investorData.residenceCard}
											onChange={handleChangeData(index)}
											placeholder={translations.documentNumber}
											status={errors[index].document ? "error" : "" }
										/>
										<span className={`error ${errors[index].document ? 'show' : null}`}>{translations.completeCampo}</span>
										<span className={`error ${errors[index].documentFormat ? 'show' : null}`}>{translations.invalidIdDocument}</span>
									</Col>
									<DocumentExpirationField
										documentExpiration={investorData.docValidThru}
										translations={translations}
										errors={errors}
										fieldName="docValidThru"
										index={index}
										handleDatePicker={handleChangeDate}	
									/>
									<CountryField
										value={investorData.countryTaxResidence}
										fieldName="countryTaxResidence"
										nationalitiesList={nationalitiesList}
										translations={translations}
										errors={errors}
										index={index}
										handleSelect={handleSelectData}
									/>
									<TextField
										size='m'
										value={investorData.taxResidence}
										fieldName="taxResidence"
										placeholder={translations.taxResidence}
										translations={translations}
										required
										index={index}
										errors={errors}
										handleChange={handleChangeData}
									/>
									<TaxIdentificationField
										placeholder={translations.taxIdentification}
										value={investorData.taxIdentification}
										fieldName="taxIdentification"
										translations={translations}
										errors={errors}
										index={index}
										handleChange={handleChangeData}
									/>
									<Col xs={24} md={24} lg={24} className="titles">
										<label>{translations.encabezadoRazon}</label>
										<Row className="nif-radio-group">
											<Radio.Group
												name="notNIFReason"
												value={investorData.notNIFReason}
												onChange={handleChangeData(index)}
											>
												<Radio className="nif-radio" value={1}> {translations.razonA}</Radio>
												<Radio className="nif-radio" value={2}>{translations.razonB}</Radio>
												{/*  Este input, sólo se habilita si se selecciona la Razón B */}
												<Input
													className="nif-radio-reason"
													placeholder={translations.placeHolderRazon}
													disabled={investorData.notNIFReason !== 2}
													name="notNIFDetailReason"
													value={investorData.notNIFDetailReason}
													onChange={handleChangeData(index)}
												/>
												<Radio className="nif-radio" value={3}>{translations.razonC}</Radio>
											</Radio.Group>
										</Row>
									</Col>
								</Row>
								<Row gutter={[16, 16]}>
									<Col xs={24} md={12} lg={12} className="titles">
										<label className="required"> {translations.employmentSituation}</label>
										<Select
											className="input-field"
											value={investorData.situation}
											onChange={handleSelectData('situation', index)}
											status={errors[index].situation ? 'error' : ''}
											placeholder="Seleccione una opción"
										>
											{activityOptionsLanguages.map((option: any) => <Option value={option[contractLanguage]}>{option[contractLanguage]}</Option>)}
										</Select>
										<span className={`error ${errors[index].situation ? 'show' : null}`}>{translations.seleccioneOpcion}</span>
									</Col>
									<>
										<Col xs={24} md={6} lg={6} className="titles">
											<label className="required"> {translations.activitySector} </label>
											<Select
												className="input-field"
												value={investorData.sector}
												onChange={handleSelectData('sector', index)}
												status={errors[index].sector ? 'error' : ''}
												placeholder="Seleccione una opción"
												showSearch
												filterOption={(input, option) =>
													(option!.children as unknown as string).includes(input)
												}
											>
												{sectorOptionsLanguages.map((option: any) => <Option value={option[contractLanguage]}>{option[contractLanguage]}</Option>)}
												<Option value="Otro">{contractLanguage === 'es' ? 'Otro/s (especificar)' : 'Other/s (specify)'}</Option>
											</Select>
											<span className={`error ${errors[index].sector ? 'show' : null}`}>{translations.seleccioneOpcion}</span>
										</Col>
										{
											!activityInList ?
												<Col xs={24} md={6} lg={6} className="titles">
													<label className="required">{translations.otherActivity}</label>
													<Input 
														name="otherSector"
														value={investorData.otherSector}
														onChange={handleChangeData(index)}
														/>
												</Col>
												:
												null
										}
									</>
									<>
										<Col xs={24} md={6} lg={6} className="titles">
											<label className="required"> {translations.fundSources} </label>
											<Select
												className="input-field"
												value={investorData.source}
												onChange={handleSelectData('source', index)}
												status={errors[index].source ? 'error' : ''}
												placeholder="Seleccione una opción"
											>
												{sourceOptionsLanguages.map((option: any) => <Option value={option[contractLanguage]}>{option[contractLanguage]}</Option>)}
												<Option value="Otro">{contractLanguage === 'es' ? 'Otro/s (especificar)' : 'Other/s (specify)'}</Option>
											</Select>
											<span className={`error ${errors[index].source ? 'show' : null}`}>{translations.seleccioneOpcion}</span>
										</Col>
										{
											!sourceInList ?
												<Col xs={24} md={6} lg={6} className="titles">
													<label className="required">{translations.otherSource}</label>
													<Input 
														name="otherSource"
														value={investorData.otherSource}
														onChange={handleChangeData(index)}
													/>
												</Col>
												:
												null
										}
									</>
									<Col xs={24} md={12} lg={12} className="titles">
										<label className="required"> {translations.anualIncome}</label>
										<Select
											className="input-field"
											value={investorData.incomes}
											onChange={handleSelectData('incomes', index)}
											status={errors[index].incomes ? 'error' : ''}
											placeholder="Seleccione una opción"
										>
											{incomeOptionsLanguages.map((option: any) => <Option value={option[contractLanguage]}>{option[contractLanguage]}</Option>)}
										</Select>
										<span className={`error ${errors[index].incomes ? 'show' : null}`}>{translations.seleccioneOpcion}</span>
									</Col>
									<TextField
										size='m'
										value={investorData.ocupation}
										fieldName="ocupation"
										translations={translations}
										required
										errors={errors}
										index={index}
										handleChange={handleChangeData}
									/>
									<TextField
										size='m'
										value={investorData.employerName}
										fieldName="employerName"
										translations={translations}
										required = {false}
										index={index}
										handleChange={handleChangeData}
									/>
									<Col xs={24} md={12} lg={12} className="titles">
										<p>
											<label className="required"> {translations.familiarPrp} </label>
											<Tooltip placement="topLeft" title={translations.tooltipFamiliarPrp} >
												<InfoCircleOutlined className="tooltip" />
											</Tooltip>
										</p>
										<Input type="text"
											name="familyPRP"
											value={investorData.familyPRP}
											onChange={handleChangeData(index)}
											status={errors[index].familyPRP ? 'error' : ''}
										/>
										<span className={`error ${errors[index].familyPRP ? 'show' : null}`}>{translations.completeCampo}, de no corresponder escriba "NA"</span>
									</Col>
									<Col xs={24} md={12} lg={12} className="titles">
										<p>
											<label className="required"> {translations.Prp} </label>
											<Tooltip placement="topLeft" title={translations.tooltipPrp} >
												<InfoCircleOutlined className="tooltip" />
											</Tooltip>
										</p>
										<Input type="text"
											name="prp"
											value={investorData.prp}
											onChange={handleChangeData(index)}
											status={errors[index].prp ? 'error' : ''}
										/>
										<span className={`error ${errors[index].prp ? 'show' : null}`}>{translations.completeCampo}, de no corresponder escriba "NA"</span>
									</Col>
								</Row>
								<Row className="titles">
									<Col xs={24} md={24} lg={24}>
										<p>
											<label> {translations.encabezadoFatca} </label>
											<Tooltip placement="topLeft" title={translations.tooltipFacta} >
												<InfoCircleOutlined className="tooltip" />
											</Tooltip>
										</p>
									</Col>
									<Col xs={24} md={24} lg={24}>
										<Checkbox
											name="american"
											checked={investorData.american}
											value= {investorData.american}
											onChange={handleCheckedData(index)}
										>
											<label>{translations.estadounidense}</label>
										</Checkbox>
									</Col>
								</Row>
								<Divider />
								{/* Esta sección, se muestra una única vez */}
								<Row gutter={[16, 16]}>
									<Col xs={24} md={12} lg={12} className="titles">
										<p>
											<label className="required"> {translations.bancoDepositos} </label>
											<Tooltip placement="topLeft" title={translations.tooltipbanco} >
												<InfoCircleOutlined className="tooltip" />
											</Tooltip>
										</p>
										<Input type="text"
											name="bankDeposits"
											value={investorData.bankDeposits}
											onChange={handleChangeData(index)}
											status={errors[index].bankDeposits ? 'error' : ''}
										/>
										<span className={`error ${errors[index].bankDeposits ? 'show' : null}`}>{translations.completeCampo}</span>
									</Col>
									<TextField
										size="m"
										value={investorData.bankAddress}
										fieldName="bankAddress"
										translations={translations}
										required
										errors={errors}
										index={index}
										handleChange={handleChangeData}
									/>
									<TextField
										size="m"
										value={investorData.iban}
										fieldName="iban"
										translations={translations}
										required
										errors={errors}
										index={index}
										handleChange={handleChangeData}
									/>
									<TextField
										size="m"
										value={investorData.biC_SWIFT}
										fieldName="biC_SWIFT"
										translations={translations}
										required
										errors={errors}
										index={index}
										handleChange={handleChangeData}
									/>
								</Row>
								<Divider />
							</>
						))
					)
					:
					<Result
						status="warning"
						title="Este contrato no existe o no está disponible"
						extra={
						<Button type="primary" key="console">
							{translations.inicio}
						</Button>
						}
					/>
				}
			</>
		</FormLayout>
	);
};

export default PhysicalInvestorForm;
