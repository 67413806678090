import { Col, Input, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import { ITaxIdentificationField } from "./ITaxIdentificationField";

const TaxIdentificationField = ({
	placeholder,
	value,
	fieldName,
	translations,
	errors,
	index,
	handleChange
}: ITaxIdentificationField) => (
	<Col xs={24} md={12} lg={12} className="titles">
		<p>
			<label> {translations.taxIdentification} </label>
			<Tooltip placement="topLeft" title={translations.tooltipIdentificacionFiscal} >
				<InfoCircleOutlined className="tooltip" />
			</Tooltip>
		</p>
		<Input
			placeholder={placeholder}
			type="text"
			name={fieldName}
			value={value}
			onChange={handleChange(index)}
			status={errors && errors[index].taxIdentification ? 'error' : ''}
		/>
	</Col>
);

export default TaxIdentificationField;