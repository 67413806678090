import {
  Button,
  Col,
  Input,
  Popconfirm,
  Row,
  Spin,
  Select,
  Radio,
  DatePicker,
  Tooltip,
  Checkbox,
  Divider,
  Switch,
} from "antd";
import {
  InfoCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";

import { IRepresentativesForm } from "./IRepresentativesForm";

import "./RepresentativesForm.scss";
import { useRepresentativesForm } from "../../../Hooks/useRepresentativesForm";
import { InvestorAPI } from "../../../Api/InvestorAPI";
import { ContractAPI } from "../../../Api/ContractAPI";
import FormLayout from "../../FormLayout/FormLayout";
import { RepresentativesAPI } from "../../../Api/RepresentativesAPI";
import { Representative } from "../../../Models/Representative";
import DateField from "../../Fields/DateField/DateField";
import CountryField from "../../Fields/CountryField/CountryField";
import NameField from "../../Fields/NameField/NameField";
import LastNameField from "../../Fields/LastNameField/LastNameField";
import DocumentTypeField from "../../Fields/DocumentTypeField/DocumentTypeField";

const { Option } = Select;

const RepresentativesForm = ({
	baseURL,
	baseURLExternal,
	translations,
	unlockNextStep,
	getAuthToken,
}: IRepresentativesForm) => {
	const representativesAPI = RepresentativesAPI(baseURL, getAuthToken);
	const investorAPI = InvestorAPI(baseURL, baseURLExternal, getAuthToken);
	const {
		loading,
		noRepresentativesError,
		errors,
		nationalitiesList,
		representatives,
		addRow,
		deleteRow,
		handleChangeData,
		handleSelectData,
		handleChangeDate,
		handleChangeSwitch,
		handleCheckedData,
		handleChangePRPData,
		saveData
	} = useRepresentativesForm({
		translations,
		representativesAPI,
		unlockNextStep,
		investorAPI,
	});

  	return (
		<FormLayout
			translations={translations}
			loading={loading}
			title={translations.representativeAttorneyData}
			onClick={saveData}
			nextStep={unlockNextStep}
			enableNextStep={(representatives.length > 0 && representatives[0].id > 0) ? true : false}
		>
     		<Row justify="space-between">
				<Col>
					<Button
						type="default"
						className="save-btn"
						/* disabled={maxNumSignatories} */
						onClick={addRow}
					>
						<PlusCircleOutlined />
						{translations.representativeAttorney}
					</Button>
				</Col>
     		</Row>
			{noRepresentativesError ? (
				<span style={{ color: "red" }}>
					Debe agregar al menos un representante
				</span>
			) : null}
      		{
				representatives.map((representative: Representative, i: number) => (
					<Col xs={24} md={24} lg={24} className="signatorieRow">
						<Row gutter={[16, 16]} align="top">
							<NameField
									name={ representative.name }
									translations={ translations}
									errors={ errors }
									index={ i }
									handleChangeData={ handleChangeData }
							/>
							<LastNameField
									lastName={ representative.lastName }
									translations={ translations }
									errors={ errors }
									index={ i }
									handleChangeData={ handleChangeData }
							/>
							<Col xs={24} md={6} lg={6} className="titles">
								<label className="required">
								{translations.representativeAttorney}
								</label>
								<Select
									value={representative.administrator}
									placeholder={"Representante / Apoderado"}
									className="input-field"
									onChange={handleSelectData("administrator", i)}
									status={errors[i].administrator ? "error" : ""}
								>
									<Option value={"Representante"}>
										{translations.representative}
									</Option>
									<Option value={"Apoderado"}>{translations.attorney}</Option>
								</Select>
								<span className={`error ${errors[i].administrator ? "show" : null}`}>
									{translations.completeCampo}
								</span>
							</Col>
							<Col xs={24} md={6} lg={6} className="titles">
								<label className="required">
									{translations.representationType}
								</label>
								<Select
									value={representative.administratorType}
									className="input-field"
									placeholder={"Tipo de Representación"}
									onChange={handleSelectData("administratorType", i)}
									status={errors[i].administratorType ? "error" : ""}
								>
									<Option value={"Único"}>{translations.sole}</Option>
									<Option value={"Solidario"}>{translations.solidary}</Option>
									<Option value={"Mancomunado"}>{translations.joint}</Option>
								</Select>
								<span className={`error ${ errors[i].administratorType ? "show" : null}`}>
									{translations.completeCampo}
								</span>
							</Col>
						</Row>
						<Row gutter={[16, 16]} align="top">
							<Col xs={24} md={6} lg={6} className="titles">
								<label className="required">
									{translations.birthDate}
								</label>
								<DatePicker
									allowClear={false}
									className="input-field"
									name="birthDate"
									value={representative.birthDate ? moment(representative.birthDate) : undefined}
									format="DD/MM/YYYY"
									onChange={handleChangeDate("birthDate", i)}
									status={errors[i].birthDate ? "error" : ""}
								/>
								<span className={`error ${errors[i].birthDate ? "show" : null}`}>
									{translations.completeCampo}
								</span>
							</Col>
							<DocumentTypeField
								value={representative.documentType}
								translations={translations}
								errors={errors}
								index={i}
								handleSelect={handleSelectData}
							/>
							<Col xs={24} md={6} lg={6} className="titles">
								<label className="required">{translations.docNumber}</label>
								<Input
									type="text"
									name="documentNumber"
									value={representative.documentNumber}
									onChange={handleChangeData(i)}
									status={errors[i].documentNumber ? "error" : ""}
									placeholder={translations.documentNumber}
								/>
								<span className={`error ${errors[i].documentNumber ? "show" : null}`}>
									{translations.completeCampo}
								</span>
								<span className={`error ${errors[i].documentFormat ? 'show' : null}`}>{translations.invalidIdDocument}</span>
							</Col>
							<Col xs={24} md={6} lg={6} className="titles">
								<label className="required">
									{translations.expirationDate}
								</label>
								<DatePicker
									allowClear={false}
									className="input-field"
									value={
										representative.documentExpiration ? moment(representative.documentExpiration): undefined
									}
									format="DD/MM/YYYY"
									onChange={handleChangeDate("documentExpiration", i)}
									status={errors[i].documentExpiration ? "error" : ""}
								/>
								<span className={`error ${errors[i].documentExpiration ? "show" : null}`}>
									{translations.completeCampo}
								</span>
								<span className={`error ${errors[i].documentExpirationInvalid ? 'show' : null}`}>{translations.invalidDate}</span>
							</Col>
							<Col xs={24} md={6} lg={6} className="titles">
								<label className="required">{translations.email}</label>
								<Input
									type="text"
									name="email"
									value={representative.email}
									onChange={handleChangeData(i)}
									status={errors[i].email ? "error" : ""}
									placeholder={translations.email}
								/>
								<span className={`error ${errors[i].email ? "show" : null}`}>
									{translations.completeCampo}
								</span>
							</Col>
							<CountryField
								value={representative.nationality}
								fieldName="nationality"
								nationalitiesList={nationalitiesList}
								translations={translations}
								errors={errors}
								index={i}
								handleSelect={handleSelectData}
							/>
							<CountryField
								value={representative.countryTaxResidence}
								fieldName="countryTaxResidence"
								nationalitiesList={nationalitiesList}
								translations={translations}
								errors={errors}
								index={i}
								handleSelect={handleSelectData}
							/>
							<DateField
								value={representative.administratorFrom}
								fieldName="administratorFrom"
								translations={translations}
								errors={errors}
								index={i}
								handleDatePicker={handleSelectData}
							/>
							<DateField
								value={representative.administratorTo}
								fieldName="administratorTo"
								translations={translations}
								errors={errors}
								index={i}
								handleDatePicker={handleSelectData}
							/>
							<Col xs={24} md={4} lg={4} className="titles">
								<div className="isSignatory">
									<label> {translations.isSignatory}</label>
									<Switch
										disabled
										checked={representative.isSignatory}
										onChange={handleChangeSwitch("isSignatory", i)}
									/>
								</div>
							</Col>
							{
								!representative.isSignatory ? 
									<Col xs={24} md={2} lg={2} className="titles" >
										<Popconfirm
										title={translations.eliminarFirmante}
										okText={translations.si}
										cancelText={translations.no}
										onConfirm={() => deleteRow(i)}
										>
										<Button danger className="dlt-btn">{translations.delete}</Button>
										</Popconfirm>
									</Col>
								: null
							}
						</Row>
						<Divider />
					</Col>
				))
      		}
			<Row gutter={[16, 16]} align="middle" className="prp5Q">
				<Col xs={24} md={24} lg={24}>
					<Checkbox
						name="prP5"
						checked={representatives[0].prP5}
						onChange={handleCheckedData(0)}
					>
						{translations.isRepresentativePRP5} 
						<Tooltip placement="topLeft" title={translations.prpTooltip}>
							<InfoCircleOutlined className="tooltip" />
						</Tooltip>
					</Checkbox>
				</Col>
			</Row>
      		{
				representatives[0].prP5 ?
					(
						<Row gutter={[16, 16]}>
							<Col xs={24} md={12} lg={12} className="titles">
								<label className="required">{translations.completeNamePRP51}</label>
								<Input
									type="text"
									name="namePRP51"
									value={representatives[0].namePRP51}
									onChange={handleChangePRPData}
									status={errors[0].namePRP51 ? "error" : ""}
								/>
								<span className={`error ${errors[0].namePRP51 ? "show" : null}`}>
									{translations.completeCampo}
								</span>
							</Col>
							<Col xs={24} md={12} lg={12} className="titles">
								<label className="required">{translations.positionPRP51}</label>
								<Input
									type="text"
									name="positionPRP51"
									value={representatives[0].positionPRP51}
									onChange={handleChangePRPData}
									status={errors[0].positionPRP51 ? "error" : ""}
								/>
								<span className={`error ${errors[0].positionPRP51 ? "show" : null}`}>
									{translations.completeCampo}
								</span>
							</Col>
							<Col xs={24} md={12} lg={12} className="titles">
								<label> {translations.completeNamePRP52}</label>
								<Input
									type="text"
									name="namePRP52"
									value={representatives[0].namePRP52}
									onChange={handleChangePRPData}
									status={errors[0].namePRP52 ? "error" : ""}
								/>
								<span className={`error ${errors[0].namePRP52 ? "show" : null}`}>
									{translations.completeCampo}
								</span>
							</Col>
							<Col xs={24} md={12} lg={12} className="titles">
								<label> {translations.positionPRP52}</label>
								<Input
									type="text"
									name="positionPRP52"
									value={representatives[0].positionPRP52}
									onChange={handleChangePRPData}
									status={errors[0].positionPRP52 ? "error" : ""}
								/>
								<span className={`error ${errors[0].positionPRP52 ? "show" : null}`}>
									{translations.completeCampo}
								</span>
							</Col>
						</Row>
					)
					: null
			}
    	</FormLayout>
  	);
};

export default RepresentativesForm;
