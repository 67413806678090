import { Col, Select } from "antd";

import { IRoleField } from "./IRoleField";

const { Option } = Select;

export const roleOptionsLanguages = [
	{ es: 'Legal person - Ownership', en: 'Legal person - Ownership' },
	{ es: 'Legal person - Other Means', en: 'Legal person - Other Means' },
	{ es: 'Legal person - Senior Managing Official', en: 'Legal person - Senior Managing Official' },
	{ es: 'Legal arrangement - Trust - Settlor', en: 'Legal arrangement - Trust - Settlor' },
	{ es: 'Legal arrangement - Trust - Trustee', en: 'Legal arrangement - Trust - Trustee' },
	{ es: 'Legal arrangement - Trust - Protector', en: 'Legal arrangement - Trust - Protector' },
	{ es: 'Legal arrangement - Trust - Beneficiary', en: 'Legal arrangement - Trust - Beneficiary' },
	{ es: 'Legal arrangement - Trust - Other', en: 'Legal arrangement - Trust - Other' },
	{ es: 'Legal arrangement - Other - Settlor or equivalent', en: 'Legal arrangement - Other - Settlor or equivalent' },
	{ es: 'Legal arrangement - Other - Trustee or equivalent', en: 'Legal arrangement - Other - Trustee or equivalent' },
	{ es: 'Legal arrangement - Other - Protector or equivalent', en: 'Legal arrangement - Other - Protector or equivalent' },
	{ es: 'Legal arrangement - Other - Beneficiary or equivalent', en: 'Legal arrangement - Other - Beneficiary or equivalent' },
	{ es: 'Legal arrangement - Other - Other or equivalent', en: 'Legal arrangement - Other - Other or equivalent' },
	{ es: 'Unknown', en: 'Unknown' },
];

const RoleField = ({
	value,
	fieldName,
	index,
	contractLanguage,
	translations,
	errors,
	handleSelect,
}: IRoleField) => (
	<Col xs={24} md={12} lg={12} className="titles">
		<label className="required"> {translations.role} </label>
		<Select
			className="input-field"
			value={value}
			onChange={handleSelect(fieldName, index || 0)}
			status={errors[index].role ? 'error' : ''}
			placeholder="Seleccione una opción"
		>
			{roleOptionsLanguages.map((option: any) => <Option value={option[contractLanguage]}>{option[contractLanguage]}</Option>)}
		</Select>
		<span className={`error ${errors[index].role ? 'show' : null}`}>{translations.seleccioneOpcion}</span>
	</Col>
);

export default RoleField;