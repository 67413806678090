import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { message, notification } from "antd";
import moment from "moment";

import { IUseInvestorData } from "./IUseInvestorData";
import { ContractData } from "../Models/Contracts";
import { InvestorDTO } from "../Models/InvestorDTO";
import { Utils } from "../Utils";
import { InvestorErrorsDTO } from "../Models/InvestorErrorsDTO";
import { emptyInvestor, emptyInvestorErrors } from "../Assets/Data/EmptyValues";

export const useInvestorData = ({
	translations,
	contractAPI,
	investorsAPI,
	institutionData,
	templatesAPI,
	selectedPersonType,
	setSelectedPersonType,
	unlockNextStep,
	userIdInstitution,
	isCreand
}: IUseInvestorData) => {
	const navigate = useNavigate();
	const [searchParams, _] = useSearchParams();

	const [loading, setLoading] = useState<boolean>(false);
	const [noContent, setNoContent] = useState<boolean>(false);
	const [contractData, setContractData] = useState<ContractData>({
		numSignatories: 1,
		idPersonType: selectedPersonType,
		commitment: 0,
		idInstitution: 0,
		vehicleId: 0,
		id: 0,
		idLanguage: 1,
		idContractState: 1,
		bp: undefined,
		container: undefined
	});
	const [error, setError] = useState<boolean>(false);
	const [templateList, setTemplateList] = useState<any>([]);
	const [investorsData, setInvestorsData] = useState<InvestorDTO[]>([]);
	const [nationalitiesList, setNationalitiesList] = useState<any[]>([]);

	const [contractErrors, setContractErrors] = useState<any>({
		noVehicle: false,
		noCommitment: false,
		noTemplate: false
	});

	const [investorsErrors, setInvestorsErrors] = useState<InvestorErrorsDTO[]>([]);

	const [contractLanguage, setContractLanguage] = useState<string>('es');

	useEffect(() => {
		if (searchParams.has('ContractId')) {
			configureInitialData(Number(searchParams.get('ContractId')));
		} else {
			setInvestorsData([emptyInvestor]);
			setInvestorsErrors([emptyInvestorErrors]);
		}
		getAllTemplates();
		getNationalities();
	}, []);

	useEffect(() => {
		templateExists();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contractData.idLanguage, 
		contractData.idPersonType, 
		contractData.vehicleId
	]);

	const configureInitialData = async(ContractId: number) => {
		setLoading(true);
		await contractAPI.getContract(ContractId).then(async(res) => {
			if (res.success) {
				if (res.data?.idLanguage){
					setContractLanguage(res.data.idLanguage === 1 ? 'es' : 'en');
				}
				await investorsAPI.getInvestors(ContractId).then((resInvestors) => {
					if (resInvestors.success) {
						const newInvestorErrors: any[] = [];
						let investors = resInvestors.data?.map((x: any) => {
							if(x.dni) x.documentType = 'dni';
							if(x.nif) x.documentType = 'nif';
							if(x.passport) x.documentType = 'passport';
							if(x.residenceCard) x.documentType = 'residenceCard';
							x.document = x.dni || x.nif || x.passport || x.residenceCard;
							newInvestorErrors.push({ ...emptyInvestorErrors});
							return x;
						});
						setInvestorsErrors(newInvestorErrors);
						let firstInvestor = resInvestors.data?.[0];
						let otherData;
						if (firstInvestor) {
							otherData = {
								vehicleId: Number(firstInvestor.idVehicle),
								commitment: firstInvestor.commitment,
								bp: firstInvestor.bp, 
								container: firstInvestor.container
							}
						}
						setSelectedPersonType(res.data.idPersonType)
						setContractData({...res.data, ...otherData});
						setInvestorsData(investors);
						setLoading(false);
					}
				});
			} else {
				setContractData({ ...contractData, idInstitution: institutionData!.idInstitution});
				setNoContent(true);
			}
		})
		.catch(err => {
			console.log(err);
		});
		setLoading(false);
	};

	const getNationalities = () => {
		investorsAPI.getCountries().then((res: any) => {
			if (res !== "") {
				setNationalitiesList(res);
			}
		})
		.catch((err: any) => {
			console.log(err);
		});
	};

	const postContract = async(data: any) => {
		await contractAPI.postContract(data).then(async (res) => {
			if(res.success && res.data){
				investorsData.map((investorData: any) => {
					if (investorData.documentType === 'dni') investorData.dni = investorData.document;
					if (investorData.documentType === 'nif') investorData.nif = investorData.document;
					if (investorData.documentType === 'passport') investorData.passport = investorData.document;
					if (investorData.documentType === 'residenceCard') investorData.residenceCard = investorData.document;
					
					return investorData;
				});
				await postInvestors(investorsData, res.data);
			}else{
				message.error(translations.messageError);
			}
			setLoading(false);
		})
		.catch(err => {
			setLoading(false);
			message.error(translations.messageError);
		})
	};

	const updateContract = async(data: any) => {
		let updateData = {...data, id: contractData.id};
		await contractAPI.updateContract(updateData).then(async (res) => {
			if (res.success) {
				investorsData.map((investorData: any) => {
					if (investorData.documentType === 'dni') investorData.dni = investorData.document;
					if (investorData.documentType === 'nif') investorData.nif = investorData.document;
					if (investorData.documentType === 'passport') investorData.passport = investorData.document;
					if (investorData.documentType === 'residenceCard') investorData.residenceCard = investorData.document;
					
					return investorData;
				});
				let newInvestors = investorsData.filter((x: any) => !x.id);
				let investorsToUpdate = investorsData.filter((x: any) => x.id && x.id !== 0);
				if(newInvestors.length > 0){
					await postInvestors(newInvestors, contractData.id);
				}
				if(investorsToUpdate.length > 0){
					await updateInvestors(investorsToUpdate);
				}
			}else{
				message.error(translations.messageError);
			}
		})
		.catch(err => {
			message.error(translations.messageError)}
		)
		setLoading(false);
	};

	const postInvestors = async(investors: any, contractId: number) => {
		let data = investors.map((x: any, index: number) => {
			if (index === 0){
				return {
					...x,
					IdContract: contractId,
					Commitment: contractData.commitment,
					IdVehicle: contractData.vehicleId,
					IdInstitutionRef: userIdInstitution,
					BP: contractData.bp,
					Container: contractData.container	
				}
			}
			return {
				...x,
				IdContract: contractId,
				Commitment: contractData.commitment,
				IdVehicle: contractData.vehicleId,
				IdInstitutionRef: userIdInstitution,
			}
		});
		await investorsAPI.postInvestors(data).then(async (resInvestors) => {
			if(resInvestors.success){
				message.success(translations.datosGuardados);
				unlockNextStep();
				navigate(`?ContractId=${contractId}`);
				setLoading(false);
			} else{
				message.error(translations.messageError);
			}
		})
	};


	const updateInvestors = async(investors: any) => {
		let data = investors.map((x: any) => {
			return {
				...x,
				commitment: contractData.commitment,
			}
		});
		await investorsAPI.updateInvestors(data).then(res => {
			if(res.success){
				message.success(translations.datosGuardados);
				setLoading(false);
			}
		})
		.catch(err => {
			setLoading(false);
			message.error(translations.messageError);
		})
	}

	const getAllTemplates = async() => {
		await templatesAPI.getTemplateList().then((res) => {
			if(res.success){
				setTemplateList(res.data);
			}
		})
		.catch(err => {
			message.error('Error with templates');
		})
	}

	const saveData = async(numSig?: number) => {
		setLoading(true);
		if (validateData()) {
			let data = {
				numSignatories: parseInt( contractData.numSignatories.toString()),
				idPersonType: contractData.idPersonType,
				idVehicle: contractData.vehicleId,
				idLanguage: contractData.idLanguage,
				bp: contractData.bp,
				container: contractData.container,
			}
			let updateData = {...contractData, numSignatories: numSig ? numSig : contractData.numSignatories};
			contractData.id ? await updateContract(updateData) : await postContract(data);
			setContractErrors((prevState: any) => ({
				...prevState,
				noVehicle: false,
				noCommitment: false,
				noTemplate: false
			}));
			const newInvestorsErrors = investorsErrors.map(() => emptyInvestorErrors);
			setInvestorsErrors(newInvestorsErrors);
		} else {
			setLoading(false);
		}
	};

	const validateData = () => {
		let isOK = true;
		if(isCreand && (!contractData.bp || !contractData.container)){
			isOK = false
			setContractErrors((prevState: any) => ({
				...prevState,
				bp: contractData.bp ? false : true,
				container: contractData.container ? false : true
			}));
		}
		if (!contractData.vehicleId) {
			setContractErrors((prevState: any) => ({
				...prevState,
				noVehicle: true
			}));
			isOK = false;
		}
		if (contractData.commitment <= 0) {
			setContractErrors((prevState: any) => ({
				...prevState,
				noCommitment: true
			}));
			isOK = false;
		}
		investorsData.forEach((investor: InvestorDTO, index: number) => {
			const investorErrors: InvestorErrorsDTO = { ...emptyInvestorErrors };

			if (!investor.name) {
				investorErrors.name = true;
				isOK = false;
			} else if (investor.name && !Utils.validate('name', investor.name)) {
				investorErrors.invalidNameFormat = true;
				isOK = false;
			}
			if (!investor.lastName) {
				investorErrors.lastName = true;
				isOK = false;
			} else if (investor.lastName && !Utils.validate('name', investor.lastName)) {
				investorErrors.invalidLastNameFormat = true;
				isOK = false;
			}
			if (!investor.birthDate) {
				investorErrors.birthDate = true;
				isOK = false;
			}
			if (!investor.nationality) {
				investorErrors.nationality = true;
				isOK = false;
			}
			if (!investor.mainResidence) {
				investorErrors.mainResidence = true;
				isOK = false;
			}
			if (!investor.mainResidenceCountry) {
				investorErrors.mainResidenceCountry = true;
				isOK = false;
			}
			if (!investor.emailContact || !Utils.validate("email", investor.emailContact)) {
				investorErrors.emailContact = true;
				isOK = false;
			}
			if (!investor.phoneNumber) {
				investorErrors.phoneNumber = true;
				isOK = false;
			}
			if (!investor.phonePrefix) {
				investorErrors.phonePrefix = true;
				isOK = false;
			}
			if (!investor.documentType) {
				investorErrors.documentType = true;
				isOK = false
			}
			if (!investor.document) {
				investorErrors.document = true;
				isOK = false;
			} else if (!/^[A-Z0-9]*$/g.test(investor.document!)) {
				investorErrors.documentFormat = true;
				isOK = false;
			}
			if (!investor.docValidThru) {
				investorErrors.documentExpiration = true;
				isOK = false;
			} else if (moment(investor.docValidThru).isBefore(moment())) {
				investorErrors.notValidExpirationDate = true;
				isOK = false;
			}
			setInvestorsErrors((prevErrors) => {
				const newErrors = [...prevErrors];
				newErrors[index] = investorErrors;
				return newErrors;
			});
	
			return isOK;
		});

		return isOK;
	};

	const getVehicleName = () => {
		const vehicle = institutionData?.institutionValues.find((x:any)=> x.vehicleId === contractData.vehicleId);
		return vehicle ? vehicle.vehicleLegalName : ''
	};

	const isAllowedAmount = async(amount: number)=> {
		if (amount) {
			await contractAPI.checkAllowedAmount(contractData.vehicleId, institutionData!.idInstitution, amount)
			.then((res: any) => {
				setError(!res.data);
				if (res.data === false) {
					notification.error({
						message: `${getVehicleName()} ${translations.limiteExcedidoTitulo}`,
						description: translations.limiteExcedido,
						duration: 10
					});
				}
				
			}).catch(err => {
				message.error(translations.messageError)
			});
		} else {
			setError(false);
		}
	};

	const onChangeVehicle = (e: any) => {
		setContractData(prevState => ({
			...prevState,
			vehicleId: e,
		}));
		setContractErrors((prevState: any) => ({
			...prevState,
			noVehicle: false
		}));
	};

	const onChangeCreandData = (e: any, type: string) => {
		setContractData(prevState => ({
			...prevState,
			[type]: e,
		}));
	}

	const onDeleteInvestor = async(index: number) => {
		let investors = [...investorsData];
		let investor = investors[index];
		let numSignatories = contractData.numSignatories - 1;
		if(investor.id){
			setLoading(true)
			await investorsAPI.deleteInvestor(investor.id).then((res: any) => {
				setLoading(false)
			})
			.catch((err: any) => {
				message.error(translations.messageError);		
				setLoading(false)

			})
			if(contractData.id > 0){
				await saveData(numSignatories);
				await configureInitialData(Number(searchParams.get('ContractId')));
			}
		}
		investors.splice(index, 1);
		setInvestorsData(investors);
		setContractData(prevState => ({
			...prevState,
			numSignatories: numSignatories
		}));
		
	}
	
	const onChangePersonType = (e: any) => {
		setContractData(prevState => ({
			...prevState,
			idPersonType: e
		}));
		setSelectedPersonType(e);
	};

	const onChangeLanguage = (e: any) => {
		setContractData(prevState => ({
			...prevState,
			idLanguage: e
		}));
	}

	const onChangeCommitment = (e: any) => {
		setContractData(prevState => ({
			...prevState,
			commitment: e
		}));
		setContractErrors((prevState: any) => ({
			...prevState,
			noCommitment: false
		}));
	};

	const handleChangeData = (index: number) => (e: any) => {
		const { name, value } = e.target;

		if(name === 'documentType'){
			const updatedElements = investorsData.map((element: any, i: number) =>
				i === index ? { ...element, [name]: value, dni: '', nif: '', residenceCard: '', } : element
			);
			setInvestorsData(updatedElements);
		} else {
			const updatedElements = investorsData.map((element: any, i: number) =>
			i === index ? { ...element, [name]: value } : element
			);
			setInvestorsData(updatedElements);
		}

		setInvestorsErrors((prevState: InvestorErrorsDTO[]) => {
			const updatedErrors = [...prevState];
			updatedErrors[index || 0] = {
				...updatedErrors[index || 0],
				[name]: false
			};
			return updatedErrors;
		});
		setInvestorsData((prevState: InvestorDTO[]) => {
			const updatedInvestors = [...prevState];
			updatedInvestors[index] = {
				...updatedInvestors[index],
				[name]: value
			};
			return updatedInvestors;
		});
	};

	const handleSelectData = (field: string, index?: number) => (value: string) => {
		setInvestorsErrors((prevState: InvestorErrorsDTO[]) => {
			const updatedErrors = [...prevState];
			updatedErrors[index || 0] = {
				...updatedErrors[index || 0],
				[field]: false
			};
			return updatedErrors;
		});
		setInvestorsData((prevState: InvestorDTO[]) => {
			const updatedInvestors = [...prevState];
			updatedInvestors[index || 0] = {
				...updatedInvestors[index || 0],
				[field]: value
			};
			return updatedInvestors;
		});
	};

	const handleChangeDate = (field: string, index: number) => (date: any) => {
		setInvestorsData((prevState: InvestorDTO[]) => {
			const updatedInvestors = [...prevState];
			updatedInvestors[index] = {
				...updatedInvestors[index],
				[field]: date ? date.format('YYYY-MM-DD') : undefined
			};
			return updatedInvestors;
		});
		setInvestorsErrors((prevState: InvestorErrorsDTO[]) => {
			const updatedErrors = [...prevState];
			updatedErrors[index || 0] = {
				...updatedErrors[index || 0],
				[field]: false
			};
			return updatedErrors;
		});
	};
	
	const addInvestor = () => {
		setInvestorsData((prevInvestorsData: any) => [
		  ...prevInvestorsData,
		  { ...emptyInvestor}
		]);	
		setContractData(prevState => ({
			...prevState,
			numSignatories: contractData.numSignatories + 1
		}));
		const newInvestorErrors = [...investorsErrors];
		newInvestorErrors.push({ ...emptyInvestorErrors });
		setInvestorsErrors(newInvestorErrors);
	  };

	  const templateExists = () => {
		const errorsCopy = {...contractErrors};
		let exists = false;

		const { idPersonType, vehicleId, idLanguage } = contractData;
		if(templateList.length === 0) return false;

		if(!idPersonType || !vehicleId || !idLanguage) return false;
		const template = templateList.find((x: any) =>
			 x.idPersonType === idPersonType
			  && x.idVehicle === vehicleId 
			  && x.idLanguage === idLanguage
		);
		
		if (!template) {
			errorsCopy.noTemplate = true;
			exists = false;
		} else {
			errorsCopy.noTemplate = false;
			exists = true;
		}
		setContractErrors(errorsCopy);
		return exists;
	}
		  
	return {
		loading,
		noContent,
		contractErrors,
		investorsErrors,
		contractData,
		contractLanguage,
		error,
		onChangeVehicle,
		onChangePersonType,
		isAllowedAmount,
		onChangeCommitment,
		saveData,
		onChangeLanguage,
		handleChangeData,
		handleSelectData,
		handleChangeDate,
		investorsData,
		addInvestor,
		onDeleteInvestor,
		onChangeCreandData,
		nationalitiesList
	};
};