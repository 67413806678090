import { Button, Col, DatePicker, Divider, Input, Popconfirm, Row } from 'antd';
import {  PlusCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

import FormLayout from '../../FormLayout/FormLayout';
import EmailField from '../../Fields/EmailField/EmailField';
import NameField from '../../Fields/NameField/NameField';
import LastNameField from '../../Fields/LastNameField/LastNameField';
import DocumentExpirationField from '../../Fields/DocumentExpirationField/DocumentExpirationField';
import CountryField from '../../Fields/CountryField/CountryField';
import FundsSourceField from '../../Fields/FundsSourceField/FundsSourceField';
import RoleField from '../../Fields/RoleField/RoleField';

import { InvestorAPI } from '../../../Api/InvestorAPI';
import { BeneficiaryAPI } from '../../../Api/BeneficiaryAPI';
import { ContractAPI } from '../../../Api/ContractAPI';
import { useBeneficiaries } from '../../../Hooks/useBeneficiaries';

import { IBeneficiaries } from './IBeneficiaries';
import { Beneficiaries as BeneficiariesModel } from '../../../Models/Beneficiaries';

import './Beneficiaries.scss';
import DateField from '../../Fields/DateField/DateField';
import TextField from '../../Fields/TextField/TextField';

const Beneficiaries = ({
	baseURL,
	baseURLExternal,
	translations,
	getAuthToken,
	unlockNextStep
}: IBeneficiaries) => {
	const investorAPI = InvestorAPI(baseURL, baseURLExternal, getAuthToken);
	const beneficiaryAPI = BeneficiaryAPI(baseURL, getAuthToken);
	const contractAPI = ContractAPI(baseURL, baseURLExternal, getAuthToken);
	const {
		loading,
		beneficiaries,
		errors,
		nationalitiesList,
		contractLanguage,
		addRow,
		getInfoTitulo,
		handleChangeData,
		handleSelectData,
		handleChangeDate,
		getInfo,
		deleteRow,
		saveData
	} = useBeneficiaries({
		translations,
		investorAPI,
		beneficiaryAPI,
		contractAPI,
		unlockNextStep
	});

	return (
		<FormLayout
			translations={translations}
			loading={loading}
			title={translations.tituloBenerficiarios}
			subTitle={<div>{translations.encabezadoBeneficiarios}<span className="tooltip-benef" onClick={getInfoTitulo}>1</span></div>}
			onClick={saveData}
			nextStep={unlockNextStep}
			enableNextStep
		>
			<Button
				className="save-btn"
				onClick={addRow}
			>
				<PlusCircleOutlined />{translations.masBeneficiarios}
			</Button>
			{beneficiaries.map((x: BeneficiariesModel, i: number) =>
					<Row gutter={[16, 16]}>
						<NameField
							name={ x.name }
							translations={ translations}
							errors={ errors }
							index={ i }
							handleChangeData={ handleChangeData }
						/>
						<LastNameField
							lastName={ x.lastName }
							translations={ translations }
							errors={ errors }
							index={ i }
							handleChangeData={ handleChangeData }
						/>
						<EmailField
							value={x.email}
							fieldName='email'
							translations={translations}
							errors={errors}
							index={i}
							handleChangeData={handleChangeData}
						/>
						<DateField
							value={x.birthDate}
							fieldName="birthDate"
							translations={translations}
							errors={errors}
							index={i}
							handleDatePicker={handleChangeDate}
						/>
						<Col xs={24} md={6} lg={6} className="titles">
							<label className="required"> {translations.direccionDomicilio}</label>
							<Input
								type="text"
								name="address"
								value={x.address}
								onChange={handleChangeData(i)}
								status={errors[i].address ? 'error' : ''}
							/>
							<span className={`error ${errors[i].address ? 'show' : null}`}>{translations.completeCampo}</span>
						</Col>
						<Col xs={24} md={6} lg={6} className="titles">
							<label className="required"> {translations.identificacionFiscalBeneficiario}</label>
							<Input
								type="text"
								name="nif"
								value={x.nif}
								onChange={handleChangeData(i)}
								status={errors[i].nif || errors[i].notValidDocument ? 'error' : ''}
							/>
							<span className={`error ${errors[i].nif ? 'show' : null}`}>{translations.completeCampo}</span>
							<span className={`error ${errors[i].notValidDocument ? 'show' : null}`}>{translations.invalidIdDocument}</span>
						</Col>
						<DocumentExpirationField
							documentExpiration={x.documentExpiration}
							translations={translations}
							errors={errors}
							fieldName='documentExpiration'
							index={i}
							handleDatePicker={handleChangeDate}
						/>
						<CountryField
							value={x.countryTaxResidence}
							fieldName="countryTaxResidence"
							nationalitiesList={nationalitiesList}
							translations={translations}
							errors={errors}
							index={i}
							handleSelect={handleSelectData}
						/>
						<TextField
							size='m'
							value={x.taxResidence}
							fieldName="taxResidence"
							placeholder={translations.taxResidence}
							translations={translations}
							required
							errors={errors}
							index={i}
							handleChange={handleChangeData}
						/>
						<CountryField
							value={x.nationality}
							fieldName='nationality'
							nationalitiesList={nationalitiesList}
							translations={translations}
							errors={errors}
							index={i}
							handleSelect={handleSelectData}
						/>
						<FundsSourceField
							value={x.fundsSource}
							fieldName='fundsSource'
							index={i}
							translations={translations}
							contractLanguage={contractLanguage}
							errors={errors}
							handleSelectOrDatePicker={handleSelectData}
						/>
						<RoleField
							value={x.role}
							fieldName='role'
							index={i}
							contractLanguage={contractLanguage}
							translations={translations}
							errors={errors}
							handleSelect={handleSelectData}
						/>
						<Col xs={24} md={6} lg={6} className="titles">
							<label className="required"> {translations.porcentajeParticipacion}</label>
							<Input
								type="number"
								name="participationPercentage"
								suffix="%"
								value={x.participationPercentage}
								onChange={handleChangeData(i)}
								status={errors[i].participationPercentage ? 'error' : ''}
							/>
							<span className={`error ${errors[i].participationPercentage ? 'show' : null}`}>{translations.completeCampo}</span>
						</Col>
						<Col xs={24} md={8} lg={8} className="titles">
							<label className="required"> {translations.prpBeneficiario} <span className="tooltip-benef" onClick={getInfo}>2</span></label>
							<Input
								type="text"
								name="prp"
								value={x.prp}
								onChange={handleChangeData(i)}
								status={errors[i].prp ? 'error' : ''}
							/>
							<span className={`error ${errors[i].prp ? 'show' : null}`}>{translations.completeCampo}</span>
						</Col>
						<DateField
							value={x.beneficiaryFrom}
							fieldName="beneficiaryFrom"
							translations={translations}
							errors={errors}
							index={i}
							handleDatePicker={handleChangeDate}
						/>
						<DateField
							value={x.beneficiaryTo}
							fieldName="beneficiaryTo"
							translations={translations}
							errors={errors}
							index={i}
							handleDatePicker={handleChangeDate}
						/>
						<Col xs={24} md={4} lg={4} className="titles">
							<Popconfirm
								title={translations.eliminarBeneficiario}
								okText={translations.si}
								cancelText={translations.no}
								onConfirm={() => deleteRow(i)}
							>
								<Button danger className="dlt-btn-benef">{translations.delete}</Button>
							</Popconfirm>
						</Col>
						<Divider />
					</Row>
			)}
		</FormLayout>
	);
};

export default Beneficiaries;
